<template>
  <div class="status-application" :class="className">
    <div class="status-application__app">
      <p class="status-application__sub-title">Заявка</p>
      <div
        class="btn"
        :class="{
          'btn--disabled': isBtnDisabled,
        }"
        @click="setCurrentCategoryData"
      >
        <Icon type="draft" v-if="!isBtnDisabled && editStatus" />
        <Icon
          type="View-On"
          v-else-if="!isBtnDisabled && !editStatus"
          style="font-size: 1.5rem"
        />
        <span>К заявке</span>
      </div>
    </div>

    <div class="status-application__link">
      <p class="status-application__sub-title">Мероприятие</p>
      <p>
        {{ data?.info_project?.title }}
      </p>
    </div>
    <div class="status-application__nomination">
      <p class="status-application__sub-title">Смена/номинация</p>
      {{ data?.info_category?.text?.title }}
      <span v-if="data?.info_project?.id !== 1 && currentNomination">
        {{ `: ${currentNomination?.title}` }}
      </span>
    </div>
    <div class="status-application__event-date">
      <p class="status-application__sub-title">Дата проведения</p>
      {{
        currentProject?.version === data.version
          ? allPeriodWorking(currentProject?.settings?.stages?.data)
          : data.year
      }}
    </div>
    <div class="status-application__status">
      <Status
        :statusValue="+currentStatus"
        :has-comments="hasComments"
        :is-status-admitted="isStatementAddmitted"
        :text="
          data?.info_project?.id === 5 && currentStatus === '100'
            ? 'Приглашён на форум'
            : data?.info_project?.id === 5 && currentStatus === '50'
            ? 'Заявка допущена'
            : undefined
        "
      ></Status>
    </div>
    <div class="status-application__btn">
      <Button
        class=""
        @click="openPopup"
        secondary
        v-if="rollbackAllowed"
        :loading="isDeleteBtnLoading"
      >
        Отозвать
      </Button>
    </div>
    <div class="status-application__id">
      <p class="status-application__sub-title">ID</p>
      <div :data-tooltip="`Дата подачи: ${data?.created_at_format}`">
        {{ data?.id }}
      </div>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Blocks/Status";
import Button from "@/components/Blocks/Button";
import { mapGetters, mapState } from "vuex";
import { allPeriodWorking } from "@/utils/helpers";
import moment from "moment";
import Icon from "@/components/Blocks/Icon";

export default {
  name: "StatusApplication",
  components: { Icon, Button, Status },
  props: {
    review: Boolean,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOldApplication: false,
    };
  },
  computed: {
    className() {
      return {
        "status-application--review": this.review,
      };
    },
    currentProject() {
      let sortedArr = JSON.parse(
        JSON.stringify(Object.values(this.projectsList))
      );
      return sortedArr?.find((item) => {
        return item.key === this.data?.info_project?.key;
      });
    },
    statuses() {
      let sortedArr =
        JSON.parse(
          JSON.stringify(
            this.storage?.systemInfo?.list_statuses[
              `${this.data?.info_project?.key}`
            ]
          )
        ) || [];
      return sortedArr;
    },
    currentStatus() {
      return Object.keys(this.statuses).find((item) => {
        return +item === this.data?.status?.key;
      });
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    selectWinnersPeriod() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "select_winners";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    validWinnersPeriod() {
      return moment().isBetween(
        moment(this.selectWinnersPeriod?.start_at),
        moment(this.selectWinnersPeriod?.end_at),
        "day",
        "[]"
      );
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 5) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "100";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 5 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else if (this.validWinnersPeriod) {
        if (this.currentProject?.id === 5) {
          boolean =
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    currentLink() {
      let link = {};
      if (this.data?.info_category?.id === 3) {
        link = { name: "ExcellentStudentProfile" };
      }
      if (this.data?.info_category?.id === 2) {
        link = { name: "SocialActiveProfile" };
      }
      if (this.data?.info_category?.id === 5) {
        link = { name: "StudentYearIndividualNomination" };
      }
      if (this.data?.info_category?.id === 6) {
        link = { name: "StudentYearCollectiveNomination" };
      }
      if (this.data?.info_category?.id === 7) {
        link = { name: "StudentYearGrandPrixNomination" };
      }
      if (this.data?.info_category?.id === 11) {
        link = { name: "AchievementYearIndividualNomination" };
      }
      if (this.data?.info_category?.id === 12) {
        link = { name: "AchievementYearCollectiveNomination" };
      }
      if (this.data?.info_category?.id === 13) {
        link = { name: "AchievementYearGrandPrixNomination" };
      }
      if (this.data?.info_category?.id === 21) {
        link = { name: "LigaForumFirstShiftProfile" };
      }
      if (this.data?.info_category?.id === 22) {
        link = { name: "LigaForumSecondShiftProfile" };
      }
      if (this.data?.info_category?.id === 23) {
        link = { name: "LigaForumThirdShiftProfile" };
      }
      if (this.data?.info_category?.id === 10) {
        link = { name: "HousingStockProfile" };
      }
      return link;
    },
    currentNomination() {
      let buffer = JSON.parse(JSON.stringify(this.data?.info_documents?.data));

      return buffer
        .sort((a, b) => {
          return (
            moment(b?.info_document?.created_at) -
            moment(a?.info_document?.created_at)
          );
        })
        .slice(-1)[0]?.info_project_tree;
    },
    editStatus() {
      return (
        (!this.isOldApplication &&
          this.validPeriodForRollback &&
          (this.currentStatus === "0" || this.currentStatus === "35")) ||
        (this.validPeriodFor35Status && this.currentStatus === "35")
      );
    },
    hasComments() {
      let Boolean;
      if (
        +this.currentStatus !== 0 &&
        +this.currentStatus !== 1 &&
        +this.currentStatus !== 100 &&
        +this.currentStatus !== 50
      ) {
        if (
          this.data?.comment_employee ||
          this.data?.expert_comments?.data?.some((item) => {
            return item.comment;
          }) ||
          this.data?.info_documents?.data?.some((item) => {
            return item.comment_expert;
          })
        ) {
          Boolean = true;
        }
      }
      return Boolean;
    },
    isStatementAddmitted() {
      return +this.currentStatus === 50;
    },
    isSGdisabled() {
      return this.data.info_project.id === 2 && this.isSPO;
    },
    isDGdisabled() {
      return this.data.info_project.id === 3 && !this.isSPO;
    },
    isNotStudent() {
      return (
        this.data.info_project.id !== 4 && this.disabledFields.isNotStudent
      );
    },
    isRTException() {
      return !this.isRT && this.data.info_project.id !== 5;
    },
    isLFFirstShiftException() {
      return this.data?.info_category?.id === 21 && !this.isSPO && !this.isRT;
    },
    isLFDisabled() {
      const currentDirection = this.directions?.info?.find(
        (item) => item?.liga_category_id === this.data.info_category.id
      );
      return (
        (currentDirection?.type_access?.includes(
          this.user?.info_educational_establishment?.type?.key === 1
            ? "vuz"
            : "suz"
        ) &&
          this.data.info_project.id === 5) ??
        true
      );
    },
    isNotRussian() {
      return (
        this.data.info_project.id === 1 &&
        this.data.info_category.id === 3 &&
        !this.disabledFields.isCitizenRussia
      );
    },
    isBtnDisabled() {
      return (
        this.isLFFirstShiftException ||
        this.isRTException ||
        this.isNotStudent ||
        this.isSGdisabled ||
        this.isDGdisabled ||
        this.isNotRussian ||
        !this.isLFDisabled
      );
    },
    ...mapGetters(["projectsList", "directions"]),
    ...mapState(["storage", "isDeleteBtnLoading"]),
    ...mapState("user", ["isSPO", "disabledFields", "isRT", "user"]),
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: { id: this.data?.id },
      });
    },
    setCurrentCategoryData() {
      if (this.isBtnDisabled) return;
      this.$store.commit("setCurrentCategoryData", this.data);
      this.$store.commit("setCurrentCategoryId", "");
      this.$store.commit("setCurrentCategoryProject", "");
      this.$store.commit("setCurrentCategoryVersion", "");
      this.$store.commit("setStatementId", "");
      if (this.currentProject?.id === 2) {
        if (this.data?.info_category?.id === 5) {
          this.$store.commit("setNominationValue", [
            "individualNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.data?.info_category?.id === 6) {
          this.$store.commit("setNominationValue", [
            "collectiveNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.data?.info_category?.id === 7) {
          this.$store.commit("setNominationValue", [
            "granprixNominationValue",
            this.currentNomination?.id,
          ]);
        }
      }
      if (this.currentProject?.id === 3) {
        if (this.data?.info_category?.id === 11) {
          this.$store.commit("setNominationValue", [
            "AYindividualNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.data?.info_category?.id === 12) {
          this.$store.commit("setNominationValue", [
            "AYcollectiveNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.data?.info_category?.id === 13) {
          this.$store.commit("setNominationValue", [
            "AYgranprixNominationValue",
            this.currentNomination?.id,
          ]);
        }
      }

      this.$router.push(this.currentLink);
    },
    allPeriodWorking,
    moment,
  },
  mounted() {
    this.isOldApplication = this.currentProject?.version !== this.data?.version;
  },
  watch: {
    data: {
      handler: function (value) {
        this.isOldApplication = this.currentProject?.version !== value.version;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.status-application {
  padding: 5px 16px;
  border-top: 1px solid var(--background--btn--profile--hover);
  border-bottom: 1px solid var(--background--btn--profile--hover);
  display: grid;
  align-items: center;
  grid-template-columns: 10% 15% 22% 18% 20% 10% 5%;
  & > div {
    justify-self: start;
    padding-left: 15px;
    @include adaptive(desktop-mid) {
      justify-self: start;
      padding-left: 0;
    }
  }
  & > a {
    color: inherit;
    pointer-events: none;
  }
  &--review {
    background-color: var(--background--application--review);
    & > a {
      @include linkWithoutUnderline();
      & a {
        pointer-events: all;
      }
    }
    & .status-application__btn {
      display: block;
    }
  }
  &__app {
    width: 100%;
    .btn {
      cursor: pointer;
      width: 100%;
      color: #fff;
      max-width: 110px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 1.2rem;
        margin-right: 5px;
      }
    }
  }
  &__link {
  }
  &__btn {
    display: none;
  }
  &__sub-title {
    font-weight: 500;
    display: none;
    color: var(--text--color);
    pointer-events: none;
    @include adaptive(desktop-mid) {
      display: block;
      margin-bottom: 8px;
    }
  }
  &__id {
    & > div {
      position: relative;
      &[data-tooltip]::after {
        text-align: center;
        left: unset;
        right: 3em;
      }
    }
  }
  @include adaptive(desktop-mid) {
    grid-template-columns: 1fr 1fr 170px;
    grid-template-rows: 40px repeat(3, 1fr);
    padding-bottom: 15px;
    row-gap: 10px;
    column-gap: 15px;
    border-top: none;
    .status-application__nomination {
      grid-row: 3 / 4;
    }
    .status-application__status {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }
    .status-application__event-date {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }
    .status-application__application-date {
    }
    .status-application__btn {
      grid-row: 2 / -1;
      align-self: center;
    }
    &__id {
      & > div {
        &[data-tooltip]::after {
          text-align: center;
          left: 4em;
          right: unset;
        }
      }
    }
  }
  @include adaptive(tablet-small) {
    padding: 15px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    & > div {
    }
    .status-application__status {
      order: -1;
      margin-bottom: 5px;
    }
    .status-application__link {
      margin-bottom: 5px;
    }
    .status-application__btn {
      align-self: flex-start;
      margin-top: 5px;
    }
  }
}
</style>
